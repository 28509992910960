import { WlMockSmsGateSendingCodes, WlMtsSmsConversionCodes } from 'src/app/services/autogen/SmsGate';
import { WlSmsTrafficConversionCodes } from 'src/app/services/autogen/SmsGate';
import { WlMtsCommunicatorConversionCodes } from 'src/app/services/autogen/SmsGate';
import { WlMockPhone } from 'src/app/services/autogen/SmsGate';
import {StrictFormControl} from '@koddington/ga-common';

export class UpdatePhoneSettingsViewModel {
  public id: number;
  public phoneNumber: string;

  public defaultSmsTrafficSendingCode: StrictFormControl<WlMockSmsGateSendingCodes>;
  public defaultMtsSendingCode: StrictFormControl<WlMockSmsGateSendingCodes>;
  public defaultMtsExoSendingCode: StrictFormControl<WlMockSmsGateSendingCodes>;
  public defaultSmsTrafficConversionCode: StrictFormControl<WlSmsTrafficConversionCodes>;
  public defaultMtsCommunicatorConversionCode: StrictFormControl<WlMtsCommunicatorConversionCodes>;
  public defaultMtsExoConversionCodes: StrictFormControl<WlMtsSmsConversionCodes>;

  constructor(phone: WlMockPhone) {
    this.id = phone.id;
    this.phoneNumber = phone.phoneNumber;

    this.defaultSmsTrafficSendingCode = new StrictFormControl<WlMockSmsGateSendingCodes>(phone.defaultSmsTrafficSendingCode);
    this.defaultMtsSendingCode = new StrictFormControl<WlMockSmsGateSendingCodes>(phone.defaultMtsSendingCode);
    this.defaultMtsExoSendingCode = new StrictFormControl<WlMockSmsGateSendingCodes>(phone.defaultMtsExoSendingCode);
    this.defaultMtsCommunicatorConversionCode =
      new StrictFormControl<WlMtsCommunicatorConversionCodes>(phone.defaultMtsCommunicatorConversionCode);
    this.defaultSmsTrafficConversionCode = new StrictFormControl<WlSmsTrafficConversionCodes>(phone.defaultSmsTrafficConversionCode);
    this.defaultMtsExoConversionCodes = new StrictFormControl<WlMtsSmsConversionCodes>(phone.defaultMtsExoConversionCode);
  }
}
