import {Injectable} from '@angular/core';
import {IDropdownStrategy} from './i-dropdown-strategy';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import { isNullOrUndefined } from '@koddington/ga-common';
import {Observable, of} from 'rxjs';
import {EnumExtensions} from '@koddington/ga-common';
import {BetPeriods, BetPeriodsExtensions} from 'src/app/services/autogen/Shared';


@Injectable({
  providedIn: 'root'
})

export class BetPeriodsStrategy implements IDropdownStrategy<BetPeriods> {

  convert(model: BetPeriods): DropdownItem<BetPeriods> {
    if (isNullOrUndefined(model)) {
      return undefined;
    }
    const item = new DropdownItem<BetPeriods>();
    item.id = model.toString();
    item.entity = model;
    item.title = BetPeriodsExtensions.format(model);

    return item;
  }

  getEntities(): Observable<Array<BetPeriods>> {
    const types = EnumExtensions.toArray(BetPeriods);
    return of(types);
  }

}
