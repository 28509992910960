<div>
  <h3>Редактирование лутбокса</h3>
  <button class="btn btn-primary mt-2" (click)="send()">Отправить запрос на обновление</button>
  <div class="col-1">
    <app-strict-input-number [title]="'Id лутбокса'" [control]="viewModel.Id"></app-strict-input-number>
  </div>
  <div *ngFor="let content of viewModel.rewards.strictValue;let i = index" class="row mt-2 align-bottom">
    <div class="col-2">
      Награда
      <app-strict-dropdown [options]="dropDownOptions"
                           [control]="content.reward"
                           [strategy]="rewardStrategy"
      ></app-strict-dropdown>
    </div>
    <div class="col-2">
      <app-strict-input-number [title]="'Вероятность'" [control]="content.probability"></app-strict-input-number>
    </div>
    <div class="col-1 my-1">
      <button class="btn btn-primary" (click)="add()" *ngIf="viewModel.rewards.strictValue.length - 1 === i">Добавить</button>
    <div class="col-1">
      <button class="btn btn-primary" (click)="delete(i)">Удалить</button>
    </div>
  </div>
</div>
<hr>
<h3>Справочник лутбоксов</h3>
<div *ngIf="!!result">
    <div class="row mt-2">
        <app-strict-input-number class="col-3"
                                 [control]="searchLootBoxId"
                                 [title]="'LootBoxId'">
        </app-strict-input-number>
        <div class="col-2 mt-4">
            <button class="btn btn-primary mb-3" (click)="search()">Поиск</button>
        </div>
    </div>
  <table class="table table-hover mt-3" >
    <thead>
    <tr class="row table-info">
      <td class="col-1">
        Id лутбокса
      </td>
      <td class="col-2">
        Результат обработки
      </td>
      <td class="col-3">
        Текст ошибка
      </td>
      <td class="col-1">
      </td>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of result.results" class="row">
      <td class="col-1">
        {{item.id}}
      </td>
      <td class="col-2">
        {{item.resultChange}}
      </td>
      <td class="col-3">
        {{item.errorText}}
      </td>
      <td class="col-1">
        <button class="btn btn-primary mt-2" (click)="change(item.id)">Редактировать</button>
      </td>
    </tr>
    </tbody>
  </table>
  <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
