import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    DropdownOptions, formatDateTime,
    GaMessagingService, GaPagedResult, GaPagingForm, GaTableCellTemplate, GaTableData, isNullOrUndefined
} from '@koddington/ga-common';
import { SearchNavigationService } from '../../../shared/services/search-navigation.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { RateRaceResultsService } from 'src/app/services/autogen/BattlePass';
import { MockRateRaceResultsPagingViewModel } from '../../../../models/rate-race/mock-rate-race-results-paging-view-model';
import { MockRateRaceResultsPagingForm } from 'src/app/services/autogen/BattlePass';
import { GaStrictModelFactory } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MockRateRaceResultsListModel } from 'src/app/services/autogen/BattlePass';
import { BooleanDropdownStrategy } from '../../../shared/common/enum-dropdown/strategies/boolean-dropdown-strategy';

@UntilDestroy()
@Component({
    selector: 'app-rate-race-results-list',
    templateUrl: './rate-race-results-list.component.html'
})
export class RateRaceResultsListComponent implements OnInit {
    @ViewChild('elemActions') elemActions: TemplateRef<any>;

    public loading = true;
    public tableData: GaTableData<MockRateRaceResultsListModel>;
    public pagedResult: GaPagedResult<MockRateRaceResultsListModel>;

    public pagingViewModel = new MockRateRaceResultsPagingViewModel();
    public isPublishedDropdownStrategy = new BooleanDropdownStrategy();
    public isHandledDropdownStrategy = new BooleanDropdownStrategy();

    constructor(private readonly _messaging: GaMessagingService,
                private readonly _navigation: SearchNavigationService,
                private readonly _activeRoute: ActivatedRoute,
                private readonly _router: Router,
                private readonly _service: RateRaceResultsService) {
    }

    public ngOnInit(): void {
        this.updateCurrentTableState();
    }

    public search(): void {
        this.pagingViewModel.offset.strictValue = 0;

        this.handleFilterChange();
    }



    public create(): void {
        this._router.navigate(['/dashboard/rate-race/main/results/create']);
    }

    public edit(id: number): void {
        this._router.navigate(['/dashboard/rate-race/main/results/update', id]);
    }

    public repeat(id: number): void {
        this._router.navigate(['/dashboard/rate-race/main/results/repeat', id]);
    }

    public publish(tableId: number) {
        this._service.publish(tableId)
            .pipe(
                take(1),
                filter(res => !this._messaging.tryShowError(res))
            )
            .subscribe(res => {
                this._messaging.showMessage(`Таблица ${tableId} опубликована.`);
                this.updateCurrentTableState();
            });
    }

    public pageChanged(form: GaPagingForm): void {
        this.pagingViewModel.offset.strictValue = form.offset;
        this.pagingViewModel.count.strictValue = form.count;

        this.handleFilterChange();
    }

    private updateCurrentTableState() {
        this._activeRoute.queryParams.pipe(
            map(value => this.initFindViewModel(value)),
            map(() => this.createForm()),
            take(1)
        ).subscribe((value) => this.load(value));
    }

    private initFindViewModel(params: Params) {
        this.pagingViewModel.battlePassSeasonId.strictValue = !isNullOrUndefined(params['battlePassSeasonId']) ? Number(params['battlePassSeasonId']) : null;
        this.pagingViewModel.offset.strictValue = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
        this.pagingViewModel.count.strictValue = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
        this.pagingViewModel.isHandled.strictValue = !isNullOrUndefined(params['isHandled']) ? params['isHandled'] !== 'false' : null;
        this.pagingViewModel.isPublished.strictValue = !isNullOrUndefined(params['isPublished']) ? params['isPublished'] !== 'false' : null;
    }

    private createForm(): MockRateRaceResultsPagingForm {
        const form = GaStrictModelFactory.fromStrictToModel(MockRateRaceResultsPagingForm, this.pagingViewModel);
        return form;
    }

    private load(form: MockRateRaceResultsPagingForm): void {
        this._service.list(form)
            .pipe(untilDestroyed(this))
            .subscribe(res => {
                this.pagedResult = res;
                this.mapTableData(res.results);

                this.loading = false;
            });
    }

    private mapTableData(results: MockRateRaceResultsListModel[]) {
        this.tableData = new GaTableData<MockRateRaceResultsListModel>()
            .addSimpleColumn(u => u.id, { title: 'Id турнира', widthSize: 100 })
            .addSimpleColumn(u => u.name, { title: 'Название', widthSize: 500 })
            .addSimpleColumn(u => u.battlePassSeasonId, { title: 'Id сезона БП', widthSize: 100 })
            .addSimpleColumn(u => formatDateTime(u.createdAt), { title: 'Создано', widthSize: 150 })
            .addSimpleColumn(u => formatDateTime(u.updatedAt), { title: 'Изменено', widthSize: 150 })
            .addSimpleColumn(u => formatDateTime(u.publishedAt), { title: 'Опубликовано', widthSize: 150 })
            .addSimpleColumn(u => formatDateTime(u.handledByBattlePassAt), { title: 'Обработано БП', widthSize: 150 })
            .addTemplateColumn(new GaTableCellTemplate(this.elemActions, (elem) => {
                return {
                    id: elem.id,
                    canPublish: isNullOrUndefined(elem.publishedAt)
                };
            }), {title: 'Действия', widthSize: 500})
            .setData(results);
    }

    private handleFilterChange(): void {
        const params: Params = {
            battlePassSeasonId: this.pagingViewModel.battlePassSeasonId.hasStrictValue ? this.pagingViewModel.battlePassSeasonId.strictValue : null,
            isPublished: this.pagingViewModel.isPublished.hasStrictValue ? String(this.pagingViewModel.isPublished.strictValue) : null,
            isHandled: this.pagingViewModel.isHandled.hasStrictValue ? String(this.pagingViewModel.isHandled.strictValue) : null,
            offset: this.pagingViewModel.offset.hasStrictValue ? this.pagingViewModel.offset.strictValue : 0,
            count: this.pagingViewModel.count.hasStrictValue ? this.pagingViewModel.count.strictValue : 30
        };

        this._navigation.search(this._activeRoute, params);
    }

    get getPublishedStrictOptions(): DropdownOptions<boolean> {
        return {placeholder: 'Не выбрано', title: 'Опубликовано:'};
    }

    get getHandledStrictOptions(): DropdownOptions<boolean> {
        return {placeholder: 'Не выбрано', title: 'Обработано:'};
    }
}
