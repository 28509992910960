import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MarketsCrudViewModel } from '../view-model/markets-crud-view-model';
import { MarketsService } from 'src/app/services/autogen/Dictionaries';
import { take } from 'rxjs/operators';
import { AddOrUpdateMarketForm } from 'src/app/services/autogen/Dictionaries';
import { GaStrictModelFactory } from '@koddington/ga-common';

@Component({
    selector: 'app-markets-update',
    templateUrl: './markets-update.component.html'
})
export class MarketsUpdateComponent implements OnInit, OnDestroy {

    @Output()
    public marketsChanged = new EventEmitter<void>();

    public loading = true;
    public viewModel = new MarketsCrudViewModel();

    constructor(private readonly _service: MarketsService) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
    }

    protected save(): void {
        const form = GaStrictModelFactory.fromStrictToModel(AddOrUpdateMarketForm, this.viewModel);

        this._service.addOrUpdate(form)
            .pipe(
                take(1)
            )
            .subscribe(() => this.marketsChanged.emit());
    }
}
