import { AbstractModelValidator } from '@koddington/ga-common';
import { EventsListStrictViewModel } from '../../../models/events/events-list-strict-view-model';
import { WlNumberMinMaxValues } from '../../../consts/wl-number-min-max-values';

export class EventsListValidator extends AbstractModelValidator<EventsListStrictViewModel> {
    constructor() {
        super();
        this.ruleForControl(u => u.count)
            .min(10)
            .max(100);

        this.ruleForControl(u => u.offset)
            .min(0);

        this.ruleForControl(u => u.userId)
            .min(1)
            .max(WlNumberMinMaxValues.Int32Max);
    }
}
