import {Component, OnInit} from '@angular/core';
import { ChangeUserSecurityGroupForm, UserSegmentationService } from 'src/app/services/autogen/Segmentation';
import {filter, finalize, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {UserSegmentationDataViewModel} from '../../../../models/segments/user-segmentation-data-view-model';
import {UserSegmentationDataValidator} from '../../validators/user-segmentation-data-validator';
import { WlUserProfile } from 'src/app/services/autogen/Segmentation';
import {WlResult} from '../../../../models/common/results/wl-result';
import {SecurityGroupsStrategy} from '../../../shared/common/strict-autocomplete/strategies/security-groups-strategy';
import { SecurityGroupsService } from 'src/app/services/autogen/Dictionaries';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-user-segmentation-data',
  templateUrl: './user-segmentation-data.component.html',
  styleUrls: ['./user-segmentation-data.component.css']
})
export class UserSegmentationDataComponent implements OnInit {

  public readonly changeGroupModel = new UserSegmentationDataViewModel();
  public readonly securityGroupStrategy: SecurityGroupsStrategy;
  public userProfiles: WlUserProfile[] = [];
  public loading = false;

  constructor(private readonly _segmentationService: UserSegmentationService,
              private readonly _validator: UserSegmentationDataValidator,
              private readonly _messaging: GaMessagingService,
              protected readonly _apiService: SecurityGroupsService) {
    this.securityGroupStrategy = new SecurityGroupsStrategy(_apiService);
  }

  public ngOnInit(): void {
    this.load();
  }

  public runSegmentation(): void {
    this._segmentationService.runSegmentation()
      .pipe(
        take(1),
        filter(u => this._messaging.tryShowError(u)),
        untilDestroyed(this)
      )
      .subscribe(() => this.load());
  }

  public changeSecurityGroup(): void {
    const errors = this._validator.validate(this.changeGroupModel);
    if (errors.length > 0) {
      return;
    }
    const form = new ChangeUserSecurityGroupForm();
    form.userId = this.changeGroupModel.userId.strictValue;
    form.securityGroupId = this.changeGroupModel.securityGroupId.strictValue.id;

    this._segmentationService.changeUserSecurityGroup(form)
      .pipe(
        take(1),
        filter(value => !this._messaging.tryShowError(value)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.load();
        this._messaging.showMessage('Группа безопасности изменена');
      });
  }

  private load(): void {
    this.loading = true;
    this._segmentationService.usersData()
      .pipe(
        take(1),
        filter((u: WlResult<WlUserProfile[]>) => !this._messaging.tryShowError(u)),
        finalize(() => this.loading = false),
        untilDestroyed(this)
      )
      .subscribe(value => this.userProfiles = value.result);
  }
}
