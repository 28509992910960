import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined, StrictFormControlBuilder } from '@koddington/ga-common';
import { ActivatedRoute, Params } from '@angular/router';
import { SearchNavigationService } from '../../shared/services/search-navigation.service';
import { StrictFormControl } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-events-layout',
    templateUrl: './events-layout.component.html',
    styleUrls: ['./events-layout.component.css']
})
export class EventsLayoutComponent implements OnInit {
    private tabsControl: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();

    constructor(private readonly activeRoute: ActivatedRoute,
                private readonly navigation: SearchNavigationService) {
    }

    ngOnInit(): void {
        this.activeRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
            if (!isNullOrUndefined(params.tab)) {
                this.tab.strictValue = params.tab;
            }
        });
    }

    onTabChange(tabName: string): void {
        const params: Params = {
            tab: tabName
        };

        this.navigation.search(this.activeRoute, params);
    }

    get tab(): StrictFormControl<string> {
        return this.tabsControl;
    }

    get isClientRegAllow(): boolean {
        return this.tab.strictValue === 'RegEvents';
    }

    get isBetOperationsAllow(): boolean {
        return this.tab.strictValue === 'BetEvents';
    }

    get isFinEventsAllow(): boolean {
        return this.tab.strictValue === 'FinEvents';
    }

    get isX5EventsAllow(): boolean {
        return this.tab.strictValue === 'X5Events';
    }

    get isFreeBetEventsAllow(): boolean {
        return this.tab.strictValue === 'FreeBetEvents';
    }

    get isSportMatchesAllow(): boolean {
        return this.tab.strictValue === 'SportMatches';
    }

    get isToursAllow(): boolean {
        return this.tab.strictValue === 'Tours';
    }

    get isScore(): boolean {
        return this.tab.strictValue === 'Score';
    }

    get isCashback(): boolean {
        return this.tab.strictValue === 'Cashback';
    }

    get isCashbackGuide(): boolean {
        return this.tab.strictValue === 'CashbackGuide';
    }

    get isCashbackBonus(): boolean {
        return this.tab.strictValue === 'CashbackBonus';
    }

    get isNationalTeamsAllow(): boolean {
        return this.tab.strictValue === 'NationalTeams';
    }
}
