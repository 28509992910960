import { Dayjs } from 'dayjs';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { WlTournamentPrizeFundTypes, WlTournamentType } from 'src/app/services/autogen/CustomTournaments';

export class CustomTournamentViewModel {
    public tournamentId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public name: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public isActive: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public tournamentType: StrictFormControl<WlTournamentType> = StrictFormControlBuilder.buildNullNumber<WlTournamentType>();
    public prizeFund: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public prizeFundType: StrictFormControl<WlTournamentPrizeFundTypes> = StrictFormControlBuilder.buildNullNumber<WlTournamentPrizeFundTypes>();
    public winnersCount: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public usersCount: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public registrationDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public startDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public endDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
}
