import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';
import { WlMockSecurityGroup } from '../../../services/autogen/Dictionaries';
import { WlGuideCashback } from '../../../services/autogen/Events';

export class AddUserCashbackViewModel {
    userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    selectDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    firstCategory: StrictFormControl<WlGuideCashback> = StrictFormControlBuilder.buildNullObject(WlGuideCashback);
    secondCategory: StrictFormControl<WlGuideCashback> = StrictFormControlBuilder.buildNullObject(WlGuideCashback);
    thirdCategory: StrictFormControl<WlGuideCashback> = StrictFormControlBuilder.buildNullObject(WlGuideCashback);
    fourthCategory: StrictFormControl<WlGuideCashback> = StrictFormControlBuilder.buildNullObject(WlGuideCashback);

}
