import { Component, OnInit } from '@angular/core';
import { CustomTournamentViewModel } from '../../../../models/custom-tournaments/custom-tournament-view-model';
import { WlCustomTournamentTypesStrategy } from '../../../shared/common/enum-dropdown/strategies/wl-custom-tournament-types-strategy';
import { CustomTournamentService } from 'src/app/services/autogen/CustomTournaments';
import { filter, take } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { GaMessagingService, GaStrictModelFactory } from '@koddington/ga-common';
import {
    AddOrUpdateCustomTournamentForm
} from '../../../../services/autogen/CustomTournaments';
import { WlTournamentPrizeFundTypesStrategy } from '../../../shared/common/enum-dropdown/strategies/wl-tournament-prize-fund-types-strategy';

@UntilDestroy()
@Component({
    selector: 'app-custom-tournaments-item-crud',
    templateUrl: './custom-tournaments-item-crud.component.html'
})
export class CustomTournamentsItemCrudComponent implements OnInit {
    public readonly viewModel: CustomTournamentViewModel = new CustomTournamentViewModel();

    constructor(private readonly service: CustomTournamentService,
                private readonly messaging: GaMessagingService,
                public readonly tournamentTypesStrategy: WlCustomTournamentTypesStrategy,
                public readonly prizeFundTypesStrategy: WlTournamentPrizeFundTypesStrategy) {
    }

    public ngOnInit(): void {
    }

    public add(): void {
        const form = GaStrictModelFactory.fromStrictToModelSimple(AddOrUpdateCustomTournamentForm, this.viewModel);
        this.service
            .addOrUpdateTournament(form)
            .pipe(
                take(1),
                filter(u => !this.messaging.tryShowError(u))
            )
            .subscribe(() => this.messaging.showMessage('Турнир успешно добавлен'));
    }
}
