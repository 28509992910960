import { IRateRaceResultsCrudStrategy } from './i-rate-race-results-crud-strategy';
import { MockRateRaceResultPlaceViewModel, MockRateRaceResultsCrudViewModel } from '../../../../../models/rate-race/mock-rate-race-results-crud-view-model';
import { MockRateRaceResultUpdateForm } from 'src/app/services/autogen/BattlePass';
import { MockRateRaceResultPlaceForm } from 'src/app/services/autogen/BattlePass';
import { filter, map, take } from 'rxjs/operators';
import { RateRaceResultsService } from 'src/app/services/autogen/BattlePass';
import { GaMessagingService, GaStrictModelFactory } from '@koddington/ga-common';
import { Observable } from 'rxjs';
import gaMap = GaStrictModelFactory.gaMap;

export class RateRaceResultUpdateStrategy implements IRateRaceResultsCrudStrategy {

    constructor(protected readonly _service: RateRaceResultsService,
                protected readonly _messaging: GaMessagingService,
                protected readonly id: number) {
    }

    public loadViewModel(): Observable<MockRateRaceResultsCrudViewModel> {
        return this._service.getForUpdate(this.id)
            .pipe(
                take(1),
                filter(res => !this._messaging.tryShowError(res)),
                map(res => this.mapFormToViewModel(res.result))
            );
    }

    public save(model: MockRateRaceResultsCrudViewModel): void {
        const form = GaStrictModelFactory.fromStrictToModel(MockRateRaceResultUpdateForm, model, [{
            sourceMapProperty: 'userPlaces',
            targetMapProperty: 'userPlaces',
            mapObject: gaMap(model.userPlaces.strictValue, u => GaStrictModelFactory.fromStrictToModel(MockRateRaceResultPlaceForm, u))
        }], null);

        this._service.update(form)
            .pipe(
                take(1),
                filter(res => !this._messaging.tryShowError(res))
            )
            .subscribe(res => {
                this._messaging.showMessage(`Таблица обновлена.`);
            });
    }

    public getHeaderText(): string {
        return `Редактирование таблицы результатов RateRace №${this.id}`;
    }

    private mapFormToViewModel(result: MockRateRaceResultUpdateForm): MockRateRaceResultsCrudViewModel {
        const viewModel = new MockRateRaceResultsCrudViewModel();
        GaStrictModelFactory.fromModelToStrict(viewModel, result, [
            {
                sourceMapProperty: 'userPlaces',
                targetMapProperty: 'userPlaces',
                mapObject: gaMap(result.userPlaces, (value) => {
                    const mapped = new MockRateRaceResultPlaceViewModel();
                    GaStrictModelFactory.fromModelToStrict(mapped, value, null, null);
                    return mapped;
                }),
            }
        ], null);

        return viewModel;
    }
}
