import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsListComponent } from './events-list/events-list.component';
import { ClientRegistrationComponent } from './events-components/client-registration/client-registration.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AddBetComponent } from './events-components/add-bet/add-bet.component';
import { BetCalculationComponent } from './events-components/bet-calculation/bet-calculation.component';
import { SharedModule } from '../shared/shared.module';
import { EventsLayoutComponent } from './events-layout/events-layout.component';
import { XFiveResultComponent } from './events-components/x-five-result/x-five-result.component';
import { AddXFiveBetComponent } from './events-components/add-x-five-bet/add-x-five-bet.component';
import { AddFreeBetComponent } from './events-components/add-free-bet/add-free-bet.component';
import { RouterModule } from '@angular/router';
import { EventsRoutes } from './events.routes';
import { AddFreeBetValidator } from './validators/add-free-bet-validator';
import { AddXFiveBetValidator } from './validators/add-x-five-bet-validator';
import { XFiveResultValidator } from './validators/x-five-result-validator';
import { AddFinanceOperationValidator } from './validators/add-finance-operation-validator';
import { ClientRegistrationValidator } from './validators/client-registration-validator';
import { AddBalanceValidator } from './validators/add-balance-validator';
import { AddBetValidator } from './validators/add-bet-validator';
import { BetCalculationValidator } from './validators/bet-calculation-validator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AddSelfieAndDocComponent } from './events-components/add-selfie-and-doc/add-selfie-and-doc.component';
import { AddSelfieAndDocValidator } from './validators/add-selfie-and-doc-validator';
import { AddCompleteIdentificationComponent } from './events-components/add-complete-identification/add-complete-identification.component';
import { ClientIdentificationValidator } from './validators/client-identification-validator';
import { AddSmsOperationsComponent } from './events-components/add-sms-operations/add-sms-operations.component';
import { ChannelChangeValidator } from './validators/channel-change-validator';
import { SportMatchesComponent } from './sport-matches/sport-matches.component';
import { ToursModule } from '../tours/tours.module';
import { AddCupisChangesComponent } from './events-components/add-cupis-changes/add-cupis-changes.component';
import { ClientInformationComponent } from './events-components/client-information/client-information.component';
import { ResetFreebetComponent } from './events-components/reset-freebet/reset-freebet.component';
import { AddDocsAndSelfieVerificationResultComponent } from './events-components/add-docs-and-selfie-verification-result/add-docs-and-selfie-verification-result.component';
import { AddRegistrationStateChangesComponent } from './events-components/add-registration-state-changes/add-registration-state-changes.component';
import { AddUserScoreUpdateComponent } from './events-components/add-user-score-update/add-user-score-update.component';
import { AddScoreUpdateValidator } from './validators/add-score-update-validator';
import { AddUserCallNotificationStatusComponent } from './events-components/add-user-call-notification-status/add-user-call-notification-status.component';
import { FavoriteTeamChangeComponent } from './events-components/favorite-team-change/favorite-team-change.component';
import { AddFavouriteNationalTeamChangesComponent } from './events-components/add-favourite-national-team-changes/add-favourite-national-team-changes.component';
import { MechanicsChangesComponent } from '../segments/components/new-segmenatation/mechanics-changes/mechanics-changes.component';
import {
    TriggerEnabledUserMechanicsComponent
} from '../segments/components/new-segmenatation/trigger-enabled-user-mechanic/trigger-enabled-user-mechanic.component';
import { MechanicsListComponent } from '../segments/components/new-segmenatation/mechanics-list/mechanics-list.component';
import { GaCommonModule } from '@koddington/ga-common';
import { WlBetEventViewModelValidator } from '../shared/common/wl-bet-event/models/wl-bet-event-view-model-validator';
import { EventsAreaCodegenAppModule } from '../../services/autogen/Events';
import { AddUserCashbackComponent } from './events-components/add-user-cashback/add-user-cashback.component';
import { AddUserCashbackValidator } from './validators/add-user-cashback-validator';
import { AddGuideCashbackComponent } from './events-components/add-guide-cashback/add-guide-cashback.component';
import { AddGuideCashbackValidator } from './events-components/add-guide-cashback/validators/add-guide-cashback-validator';
import { AddCashbackBonusComponent } from './events-components/add-cashback-bonus/add-cashback-bonus.component';
import { AddCashbackBonusValidator } from './events-components/add-cashback-bonus/validators/add-cashback-bonus-validator';


@NgModule({
    declarations: [
        EventsListComponent,
        ClientRegistrationComponent,
        AddBetComponent,
        BetCalculationComponent,
        EventsLayoutComponent,
        XFiveResultComponent,
        AddXFiveBetComponent,
        AddFreeBetComponent,
        AddSelfieAndDocComponent,
        AddCompleteIdentificationComponent,
        AddSmsOperationsComponent,
        SportMatchesComponent,
        AddCupisChangesComponent,
        ClientInformationComponent,
        ResetFreebetComponent,
        AddDocsAndSelfieVerificationResultComponent,
        AddRegistrationStateChangesComponent,
        AddUserScoreUpdateComponent,
        AddUserCallNotificationStatusComponent,
        FavoriteTeamChangeComponent,
        AddFavouriteNationalTeamChangesComponent,
        MechanicsChangesComponent,
        TriggerEnabledUserMechanicsComponent,
        MechanicsListComponent,
        AddUserCashbackComponent,
        AddGuideCashbackComponent,
        AddCashbackBonusComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(EventsRoutes),
        ReactiveFormsModule,
        SharedModule,
        MatDatepickerModule,
        ToursModule,
        GaCommonModule,
        EventsAreaCodegenAppModule
    ],
    exports: [
        AddUserCallNotificationStatusComponent,
        AddCupisChangesComponent,
        EventsLayoutComponent,
        MechanicsChangesComponent,
        TriggerEnabledUserMechanicsComponent,
        MechanicsListComponent,
        ClientRegistrationComponent,
    ],
    providers: [
        AddFreeBetValidator,
        AddXFiveBetValidator,
        XFiveResultValidator,
        AddFinanceOperationValidator,
        ClientRegistrationValidator,
        AddBalanceValidator,
        AddBetValidator,
        WlBetEventViewModelValidator,
        BetCalculationValidator,
        AddSelfieAndDocValidator,
        ClientIdentificationValidator,
        ChannelChangeValidator,
        AddScoreUpdateValidator,
        AddUserCashbackValidator,
        AddGuideCashbackValidator,
        AddCashbackBonusValidator
    ]
})
export class EventsModule {
}
