import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersListComponent} from './components/users-list/users-list.component';
import {UserValidator} from './validators/user-validator';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {UsersRoutes} from './users-routes';
import {UsersAddComponent} from './components/users-add/users-add.component';
import {UsersAddDataComponent} from './components/users-add-data/users-add-data.component';
import {AddUserDataValidator} from './validators/add-user-data-validator';
import {SharedModule} from '../shared/shared.module';
import { UsersWithoutLoginsComponent } from './components/users-without-logins/users-without-logins.component';
import {UsersLayoutComponent} from './components/layout/users-layout/users-layout.component';
import {UsersTestProfilesListComponent} from './components/users-test-profiles-list/users-test-profiles-list.component';
import {UsersFreebetStatusComponent} from './components/users-freebet-status/users-freebet-status.component';
import {UserBonusClubRewardsComponent} from './components/user-bonus-club-rewards/user-bonus-club-rewards.component';
import {UserLoginsComponent} from './components/user-logins/user-logins.component';
import {UserResetResultBetComponent} from './components/user-reset-result-bet/user-reset-result-bet.component';
import {DocumentVerificationErrorsComponent} from './components/document-verification-errors/document-verification-errors.component';
import {UserCancelFreebetComponent} from './components/user-cancel-freebet/user-cancel-freebet.component';
import { UsersBetsForSportTypeComponent } from './components/users-sports-stats/users-bets-for-sport-type/users-bets-for-sport-type.component';
import { UserSportStatsFilterComponent } from './components/users-sports-stats/user-sport-stats-filter/user-sport-stats-filter.component';
import { UserPushConversionBySportTypeComponent } from './components/users-sports-stats/user-push-conversion-by-sport-type/user-push-conversion-by-sport-type.component';
import { UsersSportTypeStatisticsComponent } from './components/users-sports-stats/users-sport-type-statistics/users-sport-type-statistics.component';
import {UsersWithoutLoginsValidator} from './validators/users-without-logins-validator';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { MatCardModule } from '@angular/material/card';
import { UpdateUserDataValidator } from './validators/update-user-data-validator';
import { UsersStagesComponent } from './components/users-stages/users-stages.component';
import { UserVideoVerificationComponent } from './components/user-video-verification/user-video-verification.component';
import {GaCommonModule} from '@koddington/ga-common';
import { UsersAreaCodegenAppModule } from '../../services/autogen/Users';
import { UsersTriggerProfileListComponent } from './components/users-trigger-profile/users-trigger-profile-list.component';

@NgModule({
  declarations: [UsersListComponent,
    UsersAddComponent,
    UsersAddDataComponent,
    UsersWithoutLoginsComponent,
    UsersLayoutComponent,
    UsersTestProfilesListComponent,
    UsersFreebetStatusComponent,
    UserBonusClubRewardsComponent,
    UserLoginsComponent,
    UserResetResultBetComponent,
    DocumentVerificationErrorsComponent,
    UserCancelFreebetComponent,
    UsersBetsForSportTypeComponent,
    UserSportStatsFilterComponent,
    UserPushConversionBySportTypeComponent,
    UsersSportTypeStatisticsComponent,
    UserDetailsComponent,
    UsersStagesComponent,
    UserVideoVerificationComponent,
    UsersTriggerProfileListComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(UsersRoutes),
    SharedModule,
    MatCardModule,
    GaCommonModule,
    UsersAreaCodegenAppModule
  ],
  providers: [
    UserValidator,
    AddUserDataValidator,
    UsersWithoutLoginsValidator,
    UpdateUserDataValidator
  ]
})
export class UsersModule {
}
