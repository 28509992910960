import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import { isNullOrUndefined } from '@koddington/ga-common';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {IAutocompleteItem, IAutocompleteStrategy, IDropdownStrategy} from '@koddington/ga-common';

import { EventsService, MockGuideCashbackListForm, WlGuideCashback } from '../../../../../services/autogen/Events';


export class CashbackCategoriesStrategy implements IAutocompleteStrategy<WlGuideCashback>, IDropdownStrategy<WlGuideCashback>  {
    private _termSource = new BehaviorSubject<string>('');
    private _source = new BehaviorSubject<WlGuideCashback[]>([]);

    constructor(private _apiService: EventsService) {
        this._termSource
            .pipe(
                switchMap((term) => {
                    return this._apiService.getGuideList(new MockGuideCashbackListForm({categoryName: term})).pipe(
                        filter(v => v.results.length > 0),
                        map(result => result.results)
                    );
                })
            ).subscribe((u) => this._source.next(u));
    }

    public convert(model: WlGuideCashback): IAutocompleteItem<WlGuideCashback> {
        return {
            entity: model,
            title: model.name,
            key: model.categoryId.toString()
        };
    }

    public getSource(): Observable<Array<WlGuideCashback>> {
        return this._source.asObservable();
    }

    public updateSource(term: string): void {
        this._termSource.next(term);
    }

    public getEntities(): Observable<Array<WlGuideCashback>> {
        return this._apiService.getGuideList(new MockGuideCashbackListForm()).pipe(
            map(u => {
                if (isNullOrUndefined(u)) { // check
                    return [];
                }

                return u.results;
            })
        );
    }

    public map(model: WlGuideCashback): DropdownItem<WlGuideCashback> {
        if (isNullOrUndefined(model)) {
            return {
                entity: model,
                title: '',
                id: null
            };
        }

        return {
            entity: model,
            title: model.name,
            id: model.categoryId.toString()
        };
    }

}

