import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { WlUserEventType } from '../../services/autogen/Events';

export class EventsListStrictViewModel {
    userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    count: StrictFormControl<number> = new StrictFormControl<number>(30);
    offset: StrictFormControl<number> = new StrictFormControl<number>(0);
    eventType: StrictFormControl<WlUserEventType> = StrictFormControlBuilder.buildNullNumber<WlUserEventType>();
    onlyLastRecord: StrictFormControl<boolean> = new StrictFormControl(false);
}
