import { StrictFormControl } from '@koddington/ga-common';
import { MockBetCalculationLineForm } from 'src/app/services/autogen/Events';

export class BetCalculationLineViewModel {
    id: StrictFormControl<number>;
    isReturn: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);

    constructor(lineId: number) {
        this.id = new StrictFormControl<number>(lineId);
        this.id.disable();
    }

    public toForm() {
        const form = new MockBetCalculationLineForm();

        form.id = this.id.strictValue;
        form.isReturn = this.isReturn.strictValue;

        return form;
    }
}
