import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GaMessagingService, GaPagedResult, GaPagingForm, GaStrictModelFactory, GaTableCellTemplate, GaTableData, isNullOrUndefined } from '@koddington/ga-common';
import { CustomTournamentService } from 'src/app/services/autogen/CustomTournaments';
import { ActivatedRoute, Params } from '@angular/router';
import { SearchNavigationService } from '../../../shared/services/search-navigation.service';
import { take, filter } from 'rxjs/operators';
import { CustomTournamentsTablePagingForm } from 'src/app/services/autogen/CustomTournaments';
import { CustomTournamentInvitationModel } from 'src/app/services/autogen/CustomTournaments';
import { CustomTournamentInvitationFormViewModel } from '../../../../models/custom-tournaments/custom-tournament-invitation-view-model';
import { CustomTournamentsInvitationForm } from 'src/app/services/autogen/CustomTournaments';

@Component({
    selector: 'app-custom-tournament-invitations',
    templateUrl: './custom-tournament-invitations.component.html'
})
export class CustomTournamentInvitationsComponent implements OnInit, OnDestroy {
    @ViewChild('templateActions') templateActions: TemplateRef<any>;

    public loading = true;
    public pagedResult: GaPagedResult<CustomTournamentInvitationModel>;
    public tableData: GaTableData<CustomTournamentInvitationFormViewModel>;
    public viewModel = new CustomTournamentInvitationFormViewModel();

    private filter = new CustomTournamentsTablePagingForm();

    constructor(private readonly service: CustomTournamentService,
                private readonly route: ActivatedRoute,
                private readonly messaging: GaMessagingService,
                private readonly navigation: SearchNavigationService) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public ngOnDestroy(): void {
    }

    public pageChanged(form: GaPagingForm): void {
        this.filter.offset = form.offset;
        this.filter.count = form.count;
        this.navigateWithParameters();
    }

    public asFormViewModel(viewModel: any): CustomTournamentInvitationFormViewModel {
        return viewModel as CustomTournamentInvitationFormViewModel;
    }

    public setInvitation(viewModel: CustomTournamentInvitationFormViewModel) {
        const form = GaStrictModelFactory.fromStrictToModel(CustomTournamentsInvitationForm, viewModel);

        return this.service.setTournamentInvitation(form)
                   .pipe(
                       filter(res => !this.messaging.tryShowError(res)),
                       take(1)
                   ).subscribe(_ => {
                this.messaging.showMessage('Параметры приглашений обновлены.');
                this.viewModel = new CustomTournamentInvitationFormViewModel();
                this.load();
            });
    }

    private init(): void {
        this.route.queryParams
            .pipe(take(1))
            .subscribe(u => this.fillFilterAndLoad(u));
    }

    private load(): void {
        this.loading = true;

        this.service.listInvitations(this.filter).pipe(
            take(1)
        ).subscribe(u => {
                this.pagedResult = u;
                this.mapToTable(u.results);

                this.loading = false;
            }
        );
    }

    private mapToTable(result: CustomTournamentInvitationModel[]) {
        this.tableData = new GaTableData<CustomTournamentInvitationFormViewModel>()
            .addSimpleColumn((elem) => elem.id.strictValue, {title: 'Id', widthSize: 60})
            .addSimpleColumn((elem) => elem.tournamentId.strictValue, {title: 'Id турнира', widthSize: 90})
            .addSimpleColumn((elem) => elem.userId.strictValue, {title: 'Id юзера', widthSize: 300})
            .addTemplateColumn(new GaTableCellTemplate(this.templateActions, (elem) => {

                return {
                    viewModel: elem
                };
            }), {title: 'Действия', widthSize: 800});

        const viewModels = result.map(u => {
            const viewModel = new CustomTournamentInvitationFormViewModel();
            GaStrictModelFactory.fromModelToStrict(viewModel, u, null, null);

            return viewModel;
        });

        this.tableData.setData(viewModels);
    }

    private fillFilterAndLoad(params: Params) {
        this.filter.offset = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
        this.filter.count = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
        this.filter.tournamentId = !isNullOrUndefined(params['tournamentId']) ? Number(params['tournamentId']) : null;

        this.load();
    }

    private navigateWithParameters(): void {
        const params: Params = {
            offset: this.filter.offset,
            count: this.filter.count ? this.filter.count : 30
        };

        this.navigation.search(this.route, params);
    }
}
