<div class="row mt-2">
  <div class="col-1">
    Укажите Event Id
  </div>
  <div class="col-1">
    Market id
  </div>
  <div class="col-1">
    Вид спорта
  </div>
  <div class="col-1">
    Id чемпионата
  </div>
  <div class="col-1">
    Id страны (игры)
  </div>
  <div class="col-1">
    Коэффициент
  </div>
  <div class="col-1">
    Период
  </div>
  <div class="col-1">
    Игрок 1
  </div>
  <div class="col-1">
    Игрок 2
  </div>
  <div class="col-1">
    ФТС
  </div>
</div>
<div class="row">
  <div class="col-1">
      <app-strict-autocomplete class="col-2"
                               placeholder="Event Id"
                               [strategy]="eventsIdStrategy"
                               [control]="controlEvent.matchInfo">
      </app-strict-autocomplete>
  </div>
  <div class="col-1">
    <app-strict-input-number [control]="controlEvent.marketId" placeholder="Маркет"></app-strict-input-number>
  </div>
  <div class="col-1">
    <app-strict-input-number [control]="controlEvent.sportType" placeholder="Вид спорта"></app-strict-input-number>
  </div>
  <div class="col-1">
    <app-strict-input-number [control]="controlEvent.championshipId" placeholder="Чемпионат"></app-strict-input-number>
  </div>
  <div class="col-1">
    <app-strict-input-number [control]="controlEvent.gameId" placeholder="Страна (игра)"></app-strict-input-number>
  </div>
  <div class="col-1">
    <app-strict-input-number [control]="controlEvent.rate" placeholder="Коэффициент линии" (valueChange)="changeRate.emit()"></app-strict-input-number>
  </div>
  <div class="col-1">
    <app-enum-dropdown [entityContainer]="controlEvent.betPeriod" [strategy]="_betPeriodStrategy"></app-enum-dropdown>
  </div>
  <div class="col-1">
    <app-strict-input-number [control]="controlEvent.firstTeamId" placeholder="id игрока 1"></app-strict-input-number>
  </div>
  <div class="col-1">
    <app-strict-input-number [control]="controlEvent.secondTeamId" placeholder="id игрока 2"></app-strict-input-number>
  </div>
  <div class="col-1">
    <app-strict-input-text [control]="controlEvent.totalScoreHandicap" placeholder="ФТС"></app-strict-input-text>
  </div>
  <div class="col-2 wl-buttons">
    <div *ngIf="showAddButton && showPanelButton" class="mt-1 ml-3">
      <a mat-menu-item class="wl-event-add-item" (click)="addClick()">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="36" height="36" rx="8" fill="#007BFF" />
          <line stroke-width="3" stroke-linecap="round" y2="18" x2="28" y1="18" x1="8" stroke="#FFFFFF" />
          <line stroke-width="3" stroke-linecap="round" y2="28" x2="18" y1="8" x1="18" stroke="#FFFFFF" />
        </svg>
      </a>
    </div>
    <div *ngIf="showDelButton" class="mt-1 ml-3">
      <a mat-menu-item class="wl-event-del-item" (click)="delClick()">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="36" height="36" rx="8" fill="#DC3545" />
          <line stroke-width="3" stroke-linecap="round" y2="18" x2="28" y1="18" x1="8" stroke="#FFFFFF" />
        </svg>
      </a>
    </div>
  </div>
</div>
