import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {filter, finalize, first, map, switchMap} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import { WlMockSmsGateAccount } from 'src/app/services/autogen/SmsGate';
import { MockSmsAccountsService } from 'src/app/services/autogen/SmsGate';
import { WlSmsChannelsExtensions } from 'src/app/services/autogen/Shared';
import { ChangeSmsAccountStateForm } from 'src/app/services/autogen/SmsGate';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.css']
})
export class AccountsListComponent implements OnInit {

  public loading = true;
  public list: WlMockSmsGateAccount[];
  public typeExtensions = WlSmsChannelsExtensions;

  constructor(private readonly _apiClient: MockSmsAccountsService,
              private readonly _activeRoute: ActivatedRoute,
              private readonly _messages: GaMessagingService) {
  }

  public ngOnInit(): void {
    this._activeRoute.queryParams
      .pipe(
        switchMap(() => this.loadList()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public refreshList(): void {
    this.loadList().subscribe();
  }

  public toggleState(item: WlMockSmsGateAccount): void {
    const form = new ChangeSmsAccountStateForm();
    form.accountId = item.id;
    form.isActive = !item.isActive;

    this._apiClient.changeAccountState(form)
      .pipe(
        first(),
        filter((u) => !this._messages.tryShowError(u)),
        switchMap(() => this.loadList()),
        finalize(() => this.loading = false),
        untilDestroyed(this)
      ).subscribe();
  }

  private loadList(): Observable<void> {
    this.loading = true;

    return this._apiClient.getList()
      .pipe(
        first(),
        filter((u) => !this._messages.tryShowError(u)),
        map(u => u.result),
        finalize(() => this.loading = false),
        map(res => {
          this.list = res;
        }),
        untilDestroyed(this)
      );
  }
}
