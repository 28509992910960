import { Component, OnInit } from '@angular/core';
import {
    formatDateTime,
    GaPagedResult,
    GaPagingForm,
    GaTableData,
    isNullOrUndefined
} from '@koddington/ga-common';
import { SearchNavigationService } from '../../shared/services/search-navigation.service';
import { ActivatedRoute, Params } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { GaStrictModelFactory } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RateUsErpService, RateUsFeedbackModel, RateUsFeedbackSearchForm } from '../../../services/autogen/RateUs';
import { RateUtsFeedbacksListViewModel } from '../../../models/rate-us/feedbacks/rate-uts-feedbacks-list.view-model';

@UntilDestroy()
@Component({
    selector: 'app-rate-us-feedback-list',
    templateUrl: './rate-us-feedback-list.component.html'
})
export class RateUsFeedbackListComponent implements OnInit {
    public loading = true;
    public tableData: GaTableData<RateUsFeedbackModel>;
    public pagedResult: GaPagedResult<RateUsFeedbackModel>;
    public pagingViewModel = new RateUtsFeedbacksListViewModel();

    constructor(private readonly _navigation: SearchNavigationService,
                private readonly _activeRoute: ActivatedRoute,
                private readonly _rateUsApi: RateUsErpService) {
    }


    public ngOnInit(): void {
        this.init();
    }

    public search(): void {
        this.pagingViewModel.offset.strictValue = 0;

        this.handleFilterChange();
    }

    public pageChanged(form: GaPagingForm): void {
        this.pagingViewModel.offset.strictValue = form.offset;
        this.pagingViewModel.count.strictValue = form.count;

        this.handleFilterChange();
    }

    private init(): void {
        this._activeRoute.queryParams.pipe(
            map(value => this.initFindViewModel(value)),
            map(() => this.createForm()),
            take(1)
        ).subscribe((value) => this.load(value));
    }

    private initFindViewModel(params: Params): void {
        this.pagingViewModel.userId.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
        this.pagingViewModel.offset.strictValue = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
        this.pagingViewModel.count.strictValue = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
    }

    private createForm(): RateUsFeedbackSearchForm {
        const form = GaStrictModelFactory.fromStrictToModel(RateUsFeedbackSearchForm, this.pagingViewModel);
        return form;
    }

    private load(form: RateUsFeedbackSearchForm): void {
        this._rateUsApi.list(form)
            .pipe(untilDestroyed(this))
            .subscribe(res => {
                this.pagedResult = res;
                this.mapTableData(res.results);

                this.loading = false;
            });
    }

    private mapTableData(results: RateUsFeedbackModel[]): void {
        this.tableData = new GaTableData<RateUsFeedbackModel>()
            .addSimpleColumn(u => u.id, { title: 'Id', widthSize: 100 })
            .addSimpleColumn(u => u.userId, { title: 'Id пользователя', widthSize: 120 })
            .addSimpleColumn(u => formatDateTime(u.createDate), { title: 'Дата создания комментария', widthSize: 200 })
            .addSimpleColumn(u => u.comment, { title: 'Комментарий', widthSize: 1300 })
            .setData(results);
    }

    private handleFilterChange(): void {
        const params: Params = {
            userId: this.pagingViewModel.userId.hasStrictValue ? String(this.pagingViewModel.userId.strictValue) : null,
            offset: this.pagingViewModel.offset.hasStrictValue ? this.pagingViewModel.offset.strictValue : 0,
            count: this.pagingViewModel.count.hasStrictValue ? this.pagingViewModel.count.strictValue : 30
        };

        this._navigation.search(this._activeRoute, params);
    }
}
