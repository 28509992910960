import { Component, OnInit } from '@angular/core';
import { AddFinanceOperationViewModel } from '../../../../models/events/components-models/add-finance-operation-view-model';
import { EventsService } from 'src/app/services/autogen/Events';
import { AddFinanceOperationValidator } from '../../validators/add-finance-operation-validator';
import { AddFinanceOperationForm } from 'src/app/services/autogen/Events';
import { filter, take } from 'rxjs/operators';
import { GaMessagingService, GaPanelGridConfig, GaStrictModelFactory, GaTitlePositions } from '@koddington/ga-common';

@Component({
    selector: 'app-add-finance-operation',
    templateUrl: './add-finance-operation.component.html'
})
export class AddFinanceOperationComponent implements OnInit {

    protected readonly model: AddFinanceOperationViewModel = new AddFinanceOperationViewModel();
    protected config = new GaPanelGridConfig();

    constructor(private readonly _service: EventsService,
                private readonly _validator: AddFinanceOperationValidator,
                private readonly _messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
        this.buildGrid();
    }

    public add(): void {
        const errors = this._validator.validate(this.model);
        if (errors.length > 0) {
            return;
        }

        const form = GaStrictModelFactory.fromStrictToModelSimple(AddFinanceOperationForm, this.model);

        this._service.operationDateAdd(form)
            .pipe(
                take(1),
                filter(res => !this._messaging.tryShowError(res))
            )
            .subscribe(() => {
                this._messaging.showMessage('Финансовая операция успешно исполненна');
            });
    }

    private buildGrid() {
        this.config.columnsCount = 5;
        this.config.titlePositions = GaTitlePositions.Top;
        this.config.addNumbers(this.model.userId, 'Id пользователя')
            .addDateTime(this.model.operationDate, 'Дата операции')
            .addNumbers(this.model.operationSum, 'Сумма операции*')
            .addDateTime(this.model.paymentSystemConfirmationDate, 'Дата подтверждения')
            .addNumbers(this.model.documentId, 'Id документа (Для кэшбека за бонус клуб - 19)')
            .addNumbers(this.model.operationId, 'Id операции')
            .addNumbers(this.model.operationSourceId, 'Id источника')
            .addText(this.model.comment, 'Комментарий');
    }
}
