import { WlBetEventViewModel } from './wl-bet-event-view-model';
import { AbstractModelValidator, isNullOrUndefined, StrictError } from '@koddington/ga-common';

export class WlBetEventViewModelValidator extends AbstractModelValidator<WlBetEventViewModel> {
    constructor() {
        super();

        this.ruleForControl(m => m.marketId)
                .required('Введите Id маркета для ставки')
                .min(1, 'Невалидное Id маркета для ставки');

        this.ruleForControlIf(m => m.matchInfo, m => isNullOrUndefined(m.matchInfo?.strictValue?.matchId))
            .required('Введите Id события ставки')
            .min(1, 'Невалидное Id события ставки');

        this.ruleForControl(m => m.rate)
                .required('Введите коэф. для ставки')
                .min(0, 'Невалидный коэф. для ставки');

        this.ruleForControl(m => m.betPeriod)
                .required('Введите период для ставки');

        this.ruleForControl(m => m.firstTeamId)
                .required('Введите id первой команды')
                .min(0, 'Невалидный id первой команды');

        this.ruleForControl(m => m.secondTeamId)
                .required('Введите id второй команды')
                .min(0, 'Невалидный id второй команды');
    }
}
