import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined, StrictFormControlBuilder } from '@koddington/ga-common';
import { StrictFormControl } from '@koddington/ga-common';
import { ActivatedRoute, Params } from '@angular/router';
import { SearchNavigationService } from '../../../../shared/services/search-navigation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-users-layout',
    templateUrl: './users-layout.component.html',
    styleUrls: ['./users-layout.component.css']
})

export class UsersLayoutComponent implements OnInit {
    private tabsControl: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();

    constructor(private readonly activeRoute: ActivatedRoute,
                private readonly navigation: SearchNavigationService) {
    }

    ngOnInit(): void {
        this.activeRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
            if (!isNullOrUndefined(params.tab)) {
                this.tab.strictValue = params.tab;
            }
        });
    }

    onTabChange(tabName: string): void {
        const params: Params = {
            tab: tabName
        };

        this.navigation.search(this.activeRoute, params);
    }

    get tab(): StrictFormControl<string> {
        return this.tabsControl;
    }

    get isUserAddTab(): boolean {
        return this.tab.strictValue === 'UserAddTab';
    }

    get isUserListTab(): boolean {
        return this.tab.strictValue === 'UserList';
    }

    get isFreeBetStatusTab(): boolean {
        return this.tab.strictValue === 'FreeBetStatus';
    }

    get isBonusClubRewardsTab(): boolean {
        return this.tab.strictValue === 'BonusClubRewards';
    }

    get isUserLogins(): boolean {
        return this.tab.strictValue === 'UserLogins';
    }

    get isResetResultBetCurrentMonthTab(): boolean {
        return this.tab.strictValue === 'ResetResultBetCurrentMonth';
    }

    get isDocsVerificationErrorsTab(): boolean {
        return this.tab.strictValue === 'DocsVerificationErrors';
    }

    get isSportsStatsTab(): boolean {
        return this.tab.strictValue === 'SportsStats';
    }

    get isStageHistoryTab(): boolean {
        return this.tab.strictValue === 'StageHistory';
    }

    get isUserVideo(): boolean {
        return this.tab.strictValue === 'VideoAttempt';
    }

    get isBackendTokens(): boolean {
        return this.tab.strictValue === 'BackendAuthTokens';
    }

    get isUsersTriggerProfile(): boolean {
        return this.tab.strictValue === 'UsersTriggerProfile';
    }
}
