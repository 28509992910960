import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { GaCommonModule } from '@koddington/ga-common';
import { DailyTasksRoutes } from './daily-tasks.routes';
import { DailyTasksLayoutComponent } from './components/daily-tasks-layout/daily-tasks-layout.component';
import { DailyTasksErpComponent } from './components/daily-tasks-erp/daily-tasks-erp.component';
import { DailyTasksErpDaysComponent } from './components/daily-tasks-erp/days/daily-task-erp-days/daily-tasks-erp-days.component';
import { PickemErpComponent } from './components/pickem-erp/pickem-erp.component';
import { PickemErpDaysComponent } from './components/pickem-erp/days/daily-task-erp-days/pickem-erp-days.component';
import { SharedAreaCodegenAppModule } from '../../services/autogen/Shared';

@NgModule({
    declarations: [
        DailyTasksLayoutComponent,
        DailyTasksErpComponent,
        DailyTasksErpDaysComponent,
        PickemErpComponent,
        PickemErpDaysComponent,
    ],
    imports: [
        ReactiveFormsModule,
        SharedModule,
        CommonModule,
        RouterModule.forChild(DailyTasksRoutes),
        GaCommonModule,
        SharedAreaCodegenAppModule
    ],
    exports: [],
    providers: []
})
export class DailyTasksModule {
}
