import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StartBroadcastViewModel } from '../../../models/broadcasts/start-broadcast-view-model';
import { StartBroadcastValidator } from '../validators/start-broadcast-validator';
import { BroadcastsService } from 'src/app/services/autogen/Broadcasts';
import { filter, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GaMessagingService, StrictError } from '@koddington/ga-common';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-broadcasts-start',
    templateUrl: './broadcasts-start.component.html',
    styleUrls: ['./broadcasts-start.component.css']
})
export class BroadcastsStartComponent implements OnInit {

    public model: StartBroadcastViewModel = new StartBroadcastViewModel();
    public userManipulationSource = new Subject<void>();
    private errors: StrictError[] = [];

    @ViewChild('fileInput') fileInputRef: ElementRef<HTMLInputElement>;

    constructor(private readonly validator: StartBroadcastValidator,
                private readonly _broadcasts: BroadcastsService,
                private readonly messaging: GaMessagingService) {
    }

    ngOnInit(): void {
        this.userManipulationSource
            .pipe(untilDestroyed(this))
            .subscribe(_ => this.validator.validate(this.model));
        this.validator.validate(this.model);
    }

    public onFileChange(event: any): void {
        if (!this.validateFile(event)) {
            return;
        }

        const file = event.target.files[0];
        this.model.file.strictValue = file;
        this.userManipulationSource.next();
    }

    save(): void {
        this.errors = this.validator.validate(this.model);
        if (this.errors.length > 0) {
            return;
        }

        this._broadcasts
            .start(this.model.templateCode?.strictValue, this.model.excludingMode?.strictValue, this.model.file?.strictValue, this.model.date?.strictValue)
            .pipe(
                take(1),
                filter(res => !this.messaging.tryShowError(res))
            )
            .subscribe(_ => {
                this.messaging.showMessage('Рассылка успешно запланирована');
                this.model = new StartBroadcastViewModel();
                this.cleanFileSelection();
            });
    }

    private validateFile(event: any): boolean {
        const files = event.target.files;

        if (files.length === 0) {
            return false;
        }

        if (files[0].size === 0) {
            this.messaging.showMessage('Выбран пустой файл');
            this.cleanFileSelection();
            return false;
        }

        return true;
    }

    private cleanFileSelection(): void {
        this.fileInputRef.nativeElement.value = '';
    }

    get valid(): boolean {
        return this.errors.length === 0;
    }
}
