import { AddFinanceOperationViewModel } from '../../../models/events/components-models/add-finance-operation-view-model';
import { Injectable } from '@angular/core';
import { AbstractModelValidator } from '@koddington/ga-common';

@Injectable()
export class AddFinanceOperationValidator extends AbstractModelValidator<AddFinanceOperationViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.operationDate)
            .required();

        this.ruleForControl(m => m.operationSum)
            .required();

        this.ruleForControl(m => m.userId)
            .requiredAndNotZero()
            .min(1);
    }
}
