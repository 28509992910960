import { IRateRaceResultsCrudStrategy } from './i-rate-race-results-crud-strategy';
import { MockRateRaceResultPlaceViewModel, MockRateRaceResultsCrudViewModel } from '../../../../../models/rate-race/mock-rate-race-results-crud-view-model';
import { RateRaceResultsService } from 'src/app/services/autogen/BattlePass';
import { MockRateRaceResultCreateForm } from 'src/app/services/autogen/BattlePass';
import { MockRateRaceResultPlaceForm } from 'src/app/services/autogen/BattlePass';
import { filter, take } from 'rxjs/operators';
import { GaMessagingService, GaStrictModelFactory } from '@koddington/ga-common';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import gaMap = GaStrictModelFactory.gaMap;

export class RateRaceResultCreateStrategy implements IRateRaceResultsCrudStrategy {

    constructor(protected readonly _service: RateRaceResultsService,
                protected readonly _router: Router,
                protected readonly _messaging: GaMessagingService) {
    }

    public loadViewModel(): Observable<MockRateRaceResultsCrudViewModel> {
        const model = new MockRateRaceResultsCrudViewModel();
        model.userPlaces.strictValue.push(new MockRateRaceResultPlaceViewModel());

        return of(model);
    }

    public save(model: MockRateRaceResultsCrudViewModel): void {
        const form = GaStrictModelFactory.fromStrictToModel(MockRateRaceResultCreateForm, model, [{
            sourceMapProperty: 'userPlaces',
            targetMapProperty: 'userPlaces',
            mapObject: gaMap(model.userPlaces.strictValue, u => GaStrictModelFactory.fromStrictToModel(MockRateRaceResultPlaceForm, u))
        }], null);

        this._service.create(form)
            .pipe(
                take(1),
                filter(res => !this._messaging.tryShowError(res))
            )
            .subscribe(res => {
                this._messaging.showMessage(`Таблица ${res.result} успешно сохранена`);
                this.navigateToUpdate(res.result);
            });
    }

    public getHeaderText(): string {
        return 'Создание новой таблицы результатов RateRace';
    }

    private navigateToUpdate(id: number) {
        this._router.navigate(['/dashboard/rate-race/main/results/update', id]);
    }
}
