import { AddBetViewModel } from '../../../models/events/components-models/add-bet-view-model';
import { Injectable } from '@angular/core';
import { WlBetTypes } from 'src/app/services/autogen/Events';
import { AbstractModelValidator, AddErrorsToControlMode, isNullOrUndefined, StrictError, ValidationLevel } from '@koddington/ga-common';
import { WlBetEventViewModelValidator } from '../../shared/common/wl-bet-event/models/wl-bet-event-view-model-validator';
import { WlNumberMinMaxValues } from '../../../consts/wl-number-min-max-values';

@Injectable()
export class AddBetValidator extends AbstractModelValidator<AddBetViewModel> {
    constructor(private readonly _betEventsValidator: WlBetEventViewModelValidator) {
        super();

        this.ruleForControl(m => m.userId)
            .requiredAndNotZero()
            .min(1, 'Минимальное значение: 1');

        this.ruleForControl(m => m.sum)
            .required()
            .min(1, 'Минимальное значение: 1');

        this.ruleForControl(m => m.betType)
            .customRule((fieldValue, model: AddBetViewModel) => {
                if (!model.betType.hasStrictValue) {
                    return new StrictError('betTypeError', 'Необходимо указать тип ставки');
                }

                if (model.events.strictValue.length < 2 && (fieldValue === WlBetTypes.System || fieldValue === WlBetTypes.Express)) {
                    return new StrictError('betTypeError', 'Данный тип ставки не поддерживает менее двух ставок');
                }

                return null;
            });

        this.ruleForControl(m => m.freeBetId)
            .min(1, 'Минимальное значение: 1')
            .max(WlNumberMinMaxValues.Int32Max, `Максимальное значение: ${WlNumberMinMaxValues.Int32Max}`);

        this.ruleForControl(m => m.platform).required();

        this.ruleForControl(m => m.betType)
            .customRule((fieldValue, model: AddBetViewModel) => {
                if (fieldValue === WlBetTypes.Express || fieldValue === WlBetTypes.System) {
                    const eventCount = Array.from(new Set(model.events.strictValue
                        .map(u => u.matchInfo.strictValue)));

                    if (model.events.strictValue.length !== eventCount.length) {
                        return new StrictError('betTypeError', 'Event Id должен быть уникальным');
                    }
                }

                return null;
            });
    }

    public override validate(model: AddBetViewModel, validationLevel?: ValidationLevel, addErrorsToControls?: AddErrorsToControlMode): StrictError[] {
        return [
            ...super.validate(model, validationLevel, addErrorsToControls),
            ...this._betEventsValidator.validateArray(model.events.strictValue, validationLevel, addErrorsToControls)
        ];
    }
}
