import { Component, OnInit } from '@angular/core';
import { WlSeasonCopySettingsViewModel } from './models/wl-season-copy-settings.view-model';
import { BattlePassService, MockBattlePassSeasonDumpForm } from '../../../../services/autogen/BattlePass';
import { formatDateTime, GaTableData, StrictError } from '@koddington/ga-common';
import { WlSeasonCopyLog } from './models/wl-season-copy-log';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlSeasonCopyValidator } from './validators/wl-season-copy.validator';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { GaStrictModelFactory } from '@koddington/ga-common';
import dayjs from 'dayjs';

@UntilDestroy()
@Component({
  selector: 'app-battle-pass-season-copy',
  templateUrl: './battle-pass-season-copy.component.html'
})
export class BattlePassSeasonCopyComponent implements OnInit {
    public copySettingsViewModel = new WlSeasonCopySettingsViewModel();
    public tableData: GaTableData<WlSeasonCopyLog>;

    public userManipulationSource = new Subject<void>();

    private _validator = new WlSeasonCopyValidator();
    private _errors: StrictError[] = [];

    constructor(private readonly _service: BattlePassService) {}

    public ngOnInit(): void {
        this.init();
    }

    public copy(): void {
        this.validate();

        if (!this.canCopy) {
            return;
        }
        const seasonId = this.copySettingsViewModel.seasonId.strictValue;
        this.log(`Копируем сезон ${seasonId}`);

        const form = GaStrictModelFactory.fromStrictToModel(MockBattlePassSeasonDumpForm, this.copySettingsViewModel, null, null, 'reuseLootBoxes');
        if (!!form.startDate) {
            form.offsetTime = '00:00:00';
        }

        this._service
            .dumpSeason(form)
            .pipe(
                take(1),
                filter(res => {
                    if (res.isCorrect) {
                        return true;
                    }

                    this.log(`Ошибка получения копии сезона ${seasonId}: ${res.error.message}`);
                    return false;
                }),
                map(res => {
                    const seasonForm = res.result;
                    this.log(`Получен дамп сезона ${seasonId} (${seasonForm.name}) для копирования`);

                    seasonForm.copySettingsForm.reuseLootBoxes = this.copySettingsViewModel.reuseLootBoxes.strictValue;

                    return seasonForm;
                }),
                switchMap(res => this._service.copySeason(res).pipe(take(1))),
                filter(res => {
                    if (res.isCorrect) {
                        return true;
                    }

                    this.log(`Ошибка создания копии сезона ${seasonId}: ${res.error.message}`);
                    return false;
                })
            )
            .subscribe(res => {
                this.log('Копия сезона создана', res.result);
            });
    }

    private init(): void {
        this.tableData = new GaTableData<WlSeasonCopyLog>()
            .addSimpleColumn(u => formatDateTime(u.logDate), { title: 'Время логгирования', widthSize: 200 })
            .addSimpleColumn(u => u.logMessage, { title: 'Сообщение', widthSize: 600 })
            .addSimpleLinkColumn(u => u.link, u => u.link, {title: 'Ссылка', widthSize: 600});

        this.tableData.setData([]);

        this.userManipulationSource
            .pipe(untilDestroyed(this))
            .subscribe(_ => this.validate());

        this.validate();
    }

    private validate(): void {
        this._errors = this._validator.validate(this.copySettingsViewModel);
    }

    private log(message: string, link?: string): void {
        const model = new WlSeasonCopyLog();
        model.logDate = dayjs();
        model.logMessage = message;
        model.link = link;

        this.tableData.data = [model, ...this.tableData.data];
    }

    get canCopy(): boolean {
        return this._errors.length === 0;
    }
}
