import { Component, OnInit } from '@angular/core';
import {
    formatDateTime,
    GaPagedResult,
    GaPagingForm, GaSearchManagementStateService,
    GaTableData,
    PagingViewModel
} from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomTournamentService, WlTournamentPrizeFundTypesExtensions } from 'src/app/services/autogen/CustomTournaments';
import { CustomTournamentModel } from 'src/app/services/autogen/CustomTournaments';
import { WlTournamentTypeExtensions } from 'src/app/services/autogen/CustomTournaments';
import { GaPagingForm as PagingForm } from '@koddington/ga-common/lib/results/results';

@UntilDestroy()
@Component({
    selector: 'app-custom-tournaments-list',
    templateUrl: './custom-tournaments-list.component.html',
    providers: [GaSearchManagementStateService]
})
export class CustomTournamentsListComponent implements OnInit {
    public pagedResult: GaPagedResult<CustomTournamentModel>;
    public tableData: GaTableData<CustomTournamentModel>;
    private pagingViewModel = new PagingViewModel();

    constructor(private readonly service: CustomTournamentService,
                private readonly _searchService: GaSearchManagementStateService<GaPagedResult<CustomTournamentModel>>) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public pageChanged(form: GaPagingForm): void {
        this._searchService.nextSearch(form);
    }

    private init() {
        this._searchService.init(u => this.service.listTournaments(u as PagingForm), this.pagingViewModel);
        this._searchService.searchState.pipe(untilDestroyed(this)).subscribe(u => {
            this.pagedResult = u;
            this.mapToTable(u.results);

            this._searchService.loadingStatus = false;
        });
    }

    private mapToTable(result: CustomTournamentModel[]) {
        this.tableData = new GaTableData<CustomTournamentModel>()
            .setData(result)
            .addSimpleColumn((elem) => elem.id, {title: 'Id', widthSize: 60})
            .addSimpleColumn((elem) => elem.tournamentId, {title: 'Id турнира', widthSize: 90})
            .addSimpleColumn((elem) => elem.name, {title: 'Наименование', widthSize: 290})
            .addSimpleColumn((elem) => WlTournamentTypeExtensions.format(elem.tournamentType), {title: 'Тип сущности', widthSize: 300})
            .addSimpleColumn((elem) => elem.isActive ? 'Да' : 'Нет', {title: 'Активен', widthSize: 80})
            .addSimpleColumn((elem) => elem.prizeFund, {title: 'Призовой фонд', widthSize: 120})
            .addSimpleColumn((elem) => WlTournamentPrizeFundTypesExtensions.format(elem.prizeFundType), {title: 'Тип призового фонда', widthSize: 160})
            .addSimpleColumn((elem) => elem.winnersCount, {title: 'Количество призёров', widthSize: 170})
            .addSimpleColumn((elem) => elem.usersCount, {title: 'Количество участников', widthSize: 170})
            .addSimpleColumn((elem) => formatDateTime(elem.registrationDate), {title: 'Дата регистрации', widthSize: 130})
            .addSimpleColumn((elem) => formatDateTime(elem.startDate), {title: 'Дата начала', widthSize: 120})
            .addSimpleColumn((elem) => formatDateTime(elem.endDate), {title: 'Дата завершения', widthSize: 130});
    }

    get loading(): boolean {
        return this._searchService.loadingStatus;
    }
}
