import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TriggersModule } from './modules/triggers/triggers.module';
import { MechanicsModule } from './modules/mechanics/mechanics.module';
import { CouponsModule } from './modules/dictionaries/coupons/coupons.module';
import { UsersModule } from './modules/users/users.module';
import { PushModule } from './modules/push/push.module';
import { SharedModule } from './modules/shared/shared.module';
import { SegmentsModule } from './modules/segments/segments.module';
import { EventsModule } from './modules/events/events.module';
import { BroadcastsModule } from './modules/broadcasts/broadcasts.module';
import { AppRoutes } from './app.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutsModule } from './modules/layouts/layouts.module';
import { BlockUIModule } from 'ng-block-ui';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { WinlineApiStatesComponent } from './modules/control-panel/winline-server/components/winline-api-states/winline-api-states.component';
import { BalancesListComponent } from './modules/dictionaries/balances/components/balances-list/balances-list.component';
import { UpdateBalanceFormComponent } from './modules/dictionaries/balances/components/update-balance-form/update-balance-form.component';
import {
    SecurityGroupsListComponent
} from './modules/dictionaries/security-groups/components/security-groups-list/security-groups-list.component';
import {
    UpdateSecurityGroupFormComponent
} from './modules/dictionaries/security-groups/components/update-security-group-form/update-security-group-form.component';
import { AccountsListComponent } from './modules/sms-gate/components/accounts/accounts-list/accounts-list.component';
import { PhonesListComponent } from './modules/sms-gate/components/phones/phones-list/phones-list.component';
import { SentListComponent } from './modules/sms-gate/components/sms/sent-list/sent-list.component';
import { CreateSmsAccountComponent } from './modules/sms-gate/components/accounts/create-sms-account/create-sms-account.component';
import { PhonesListItemComponent } from './modules/sms-gate/components/phones/phones-list-item/phones-list-item.component';
import { SentMessageInfoComponent } from './modules/sms-gate/components/sms/sent-message-info/sent-message-info.component';
import { MatCardModule } from '@angular/material/card';
import { SportTypeUpdateComponent } from './modules/dictionaries/sport-types/components/sport-type-update/sport-type-update.component';
import { SportTypesListComponent } from './modules/dictionaries/sport-types/components/sport-types-list/sport-types-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { PushGateMockModule } from './modules/push-gate/push-gate.module';
import { CountriesListComponent } from './modules/dictionaries/countries/components/countries-list/countries-list.component';
import { CountriesUpdateComponent } from './modules/dictionaries/countries/components/countries-update/countries-update.component';
import { MarketsUpdateComponent } from './modules/dictionaries/markets/components/markets-update/markets-update.component';
import { MarketsListComponent } from './modules/dictionaries/markets/components/markets-list/markets-list.component';
import { MediaModule } from './modules/media/media.module';
import { GaCommonModule } from '@koddington/ga-common';
import {
    AddMatchTeamComponent
} from './modules/dictionaries/match-teams/components/add-match-team/add-match-team.component';
import {
    MatchTeamsListComponent
} from './modules/dictionaries/match-teams/components/match-teams-list/match-teams-list.component';
import { BattlePassModule } from './modules/battle-pass/battle-pass.module';
import { AttemptsListComponent } from './modules/sms-gate/components/attempts/attempts-list/attempts-list.component';
import { DailyTasksRoutes } from './modules/daily-tasks/daily-tasks.routes';
import { DailyTasksModule } from './modules/daily-tasks/daily-tasks.module';
import { AttemptsMessageInfoComponent } from './modules/sms-gate/components/attempts/attempts-message-info/attempts-message-info.component';
import { CustomRouteReuseStrategy } from './services/route-reuse-strategy/custom-route-reuse-strategy';

@NgModule({
    declarations: [
        AppComponent,
        AddMatchTeamComponent,
        MatchTeamsListComponent,
        WinlineApiStatesComponent,
        BalancesListComponent,
        UpdateBalanceFormComponent,
        SecurityGroupsListComponent,
        UpdateSecurityGroupFormComponent,
        AccountsListComponent,
        PhonesListComponent,
        SentListComponent,
        AttemptsListComponent,
        AttemptsMessageInfoComponent,
        CreateSmsAccountComponent,
        PhonesListItemComponent,
        PhonesListComponent,
        SentMessageInfoComponent,
        SportTypeUpdateComponent,
        SportTypesListComponent,
        CountriesListComponent,
        CountriesUpdateComponent,
        MarketsUpdateComponent,
        MarketsListComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        HttpClientModule,
        LayoutsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatIconModule,
        FormsModule,
        BlockUIModule.forRoot(),
        RouterModule.forRoot(AppRoutes),
        TriggersModule,
        MechanicsModule,
        CouponsModule,
        UsersModule,
        PushModule,
        SharedModule,
        SegmentsModule,
        EventsModule,
        BroadcastsModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatSortModule,
        PushGateMockModule,
        MediaModule,
        GaCommonModule,
        BattlePassModule,
        DailyTasksModule
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
