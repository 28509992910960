import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { IPagingViewModel } from '@koddington/ga-common';

export class MarketsSearchModel implements IPagingViewModel {
    offset: StrictFormControl<number> = new StrictFormControl<number>(0);
    count: StrictFormControl<number> = new StrictFormControl<number>(30);
    term: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    id: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    isDeleted: StrictFormControl<boolean> = StrictFormControlBuilder.buildNullBoolean(); // selector
}
