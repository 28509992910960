import { Injectable } from '@angular/core';
import { AbstractModelValidator, isNullOrUndefined } from '@koddington/ga-common';
import { AddUserCashbackViewModel } from '../../../models/events/components-models/add-user-cashback-view-model';
import { CallOperationTypes } from '../../../services/autogen/Events';

@Injectable()
export class AddUserCashbackValidator extends AbstractModelValidator<AddUserCashbackViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.userId).requiredAndNotZero().min(1);
        this.ruleForControlIf(m => m.firstCategory,
            c => isNullOrUndefined(c?.firstCategory?.strictValue?.categoryId) )
            .requiredAndNotZero('Поле обязательно');
        this.ruleForControlIf(m => m.secondCategory,
            c => isNullOrUndefined(c?.secondCategory?.strictValue?.categoryId) )
            .requiredAndNotZero('Поле обязательно');
        this.ruleForControlIf(m => m.thirdCategory,
            c => isNullOrUndefined(c?.thirdCategory?.strictValue?.categoryId) )
            .requiredAndNotZero('Поле обязательно');
        this.ruleForControlIf(m => m.fourthCategory,
            c => isNullOrUndefined(c?.fourthCategory?.strictValue?.categoryId) )
            .requiredAndNotZero('Поле обязательно');
        this.ruleForControl(m => m.selectDate).required();
    }
}
