<div>
    <h3>Ставка</h3>
    <div class="row">
        <div class="col-2">
            <app-enum-dropdown [entityContainer]="model.betType"
                               [strategy]="typeStrategy"
                               [options]="options"></app-enum-dropdown>
        </div>
        <div class="col-3">
        </div>
        <div class="col-3 warning">
            {{this.warning}}
        </div>
    </div>
    <div>
        <div class="row mt-2">
            <div class="col-2">
                Введите Id пользователя
            </div>
            <div class="col-2">
                Укажите дату ставки
            </div>
            <div class="col-1">
                Укажите FreeBet Id
            </div>
            <div class="col-1">
                Id ставки
            </div>
            <div class="col-1">
                Общий коэффициент
            </div>
            <div class="col-1">
                Сумма ставки
            </div>
            <div class="col-1">
                Платформа
            </div>
        </div>
        <div [ngClass]="addBetNgClass">
            <div class="col-2">
                <app-strict-input-number [control]="model.userId" placeholder="Id пользователя"></app-strict-input-number>
            </div>
            <div class="col-2">
                <strict-date-time-picker [control]="model.betDate"></strict-date-time-picker>
            </div>
            <div class="col-1">
                <app-strict-input-number [control]="model.freeBetId" placeholder="Freebet Id"></app-strict-input-number>
            </div>
            <div class="col-1">
                <app-strict-input-number [control]="model.betId" placeholder="Bet Id"></app-strict-input-number>
            </div>
            <div class="col-1">
                <app-strict-input-number [control]="model.totalRate" placeholder="Коэффициент"></app-strict-input-number>
            </div>
            <div class="col-1">
                <app-strict-input-number [control]="model.sum" placeholder="Сумма"></app-strict-input-number>
            </div>
            <div class="col-2">
                <app-enum-dropdown [entityContainer]="model.platform" [strategy]="platformStrategy"></app-enum-dropdown>
            </div>
        </div>
        <app-wl-bet-event (add)="addEvent()"
                          (del)="delEvent(i)"
                          [eventsIdStrategy]="eventsIdStrategy"
                          (changeRate)="changeRate.next()"
                          *ngFor="let event of model.events.strictValue; let i = index"
                          [controlEvent]=model.events.strictValue[i]
                          [betType]="model.betType"
                          [length]=this.model.events.strictValue.length
                          [index]=i>
        </app-wl-bet-event>
        <button class="btn btn-primary m-2" (click)="tryAddBet()">Сохранить</button>
        <button class="btn btn-primary m-2" (click)="fillEmptyFields()">Заполнить пустые поля</button>
        <button class="btn btn-primary m-2" (click)="calculateByDefaultValue()">Рассчитать значением по умолчанию</button>
    </div>
</div>
<hr/>
