import {Component, OnDestroy, OnInit} from '@angular/core';
import { WlXfiftyBetInternalModel } from 'src/app/services/autogen/Events';
import { WlFilteredPagedResult } from 'src/app/services/autogen/Shared';
import { GaPagingForm, StrictFormControl } from '@koddington/ga-common';

@Component({
  selector: 'app-x50-bets-list',
  templateUrl: './x50-bets-list.component.html',
  styleUrls: ['./x50-bets-list.component.css']
})
export class X50BetsListComponent implements OnInit, OnDestroy {

  result: WlFilteredPagedResult<WlXfiftyBetInternalModel, GaPagingForm>;
  offset: StrictFormControl<number> = new StrictFormControl<number>(0);
  loading: boolean;

  constructor() {
  }

  ngOnInit(): void { }

  ngOnDestroy() {
  }

  pageChanged(form: GaPagingForm): void {
    this.offset.strictValue = form.offset;
  }
  loadingStatus(value: boolean): void {
    this.loading = value;
  }
  resultsHandler(value: WlFilteredPagedResult<WlXfiftyBetInternalModel, GaPagingForm>): void {
    this.result = value;
  }
}
