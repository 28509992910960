import { Component, OnInit } from '@angular/core';
import { BetCalculationViewModel } from '../../../../models/events/components-models/bet-calculation-view-model';
import { EventsService, WlBetTypes } from 'src/app/services/autogen/Events';
import { BetCalculationValidator } from '../../validators/bet-calculation-validator';
import { debounceTime, distinctUntilChanged, filter, first, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GaConfirmationService, GaMessagingService, isNullOrUndefined } from '@koddington/ga-common';
import { BetCalculationLineViewModel } from '../../../../models/events/components-models/bet-calculation-line-view-model';
import dayjs from 'dayjs';

@UntilDestroy()
@Component({
    selector: 'app-bet-calculation',
    templateUrl: './bet-calculation.component.html'
})
export class BetCalculationComponent implements OnInit {

    public model: BetCalculationViewModel;

    constructor(private readonly _service: EventsService,
                private readonly _validator: BetCalculationValidator,
                private readonly _messaging: GaMessagingService,
                private readonly _confirmation: GaConfirmationService) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public tryAddBetCalculation(): void {
        const errors = this._validator.validate(this.model);
        if (errors.length > 0) {
            return;
        }

        if (this.model.eventDate.hasStrictValue && this.model.eventDate.strictValue > dayjs()) {
            return this.saveBetCalculation();
        }

        const warningMessage = this.model.eventDate.hasStrictValue
            ? 'Указанное время расчёта раньше текущего времени. Всё равно добавить?'
            : 'Время расчёта не указано и будет заменено на время добавления. Всё равно добавить?';
        this._confirmation.openDialog(warningMessage)
            .pipe(
                take(1),
                filter(u => u === true)
            )
            .subscribe(_ => this.saveBetCalculation());
    }

    private init(): void {
        this.resetViewModel();
    }

    private resetViewModel(): void {
        this.model = new BetCalculationViewModel();
        this.model.betId.strictValueSource.pipe(
            distinctUntilChanged(),
            debounceTime(400),
            untilDestroyed(this),
            filter(u => !isNullOrUndefined(u))
        ).subscribe((u) => {
            this.model.hasBet = false;
            this.loadBetInfo(u);
        });
    }

    private loadBetInfo(betId: number) {
        this._service.getBetWithEvents(betId).pipe(
            take(1)
        ).subscribe(u => {
            if (this._messaging.tryShowError(u)) {
                return;
            }

            this.model.hasBet = true;
            this.model.betId.setErrors(null);
            this.model.expressLines.strictValue = u.result.some(v => v.betType === WlBetTypes.Express)
                ? u.result.map(v => new BetCalculationLineViewModel(v.lineId))
                : new Array<BetCalculationLineViewModel>();
        });
    }

    private saveBetCalculation(): void {
        const form = this.model.toForm();

        this._service.addBetCalculationEvent(form)
            .pipe(
                first(),
                filter(u => !this._messaging.tryShowError(u)),
                untilDestroyed(this)
            )
            .subscribe(_ => {
                this._messaging.showMessage('Расчёт ставки успешно выполнен');
                this.resetViewModel();
            });
    }

    get isRedeem(): boolean {
        return this.model.isRedeem.strictValue;
    }

    get hasExpressLines(): boolean {
        return this.model.expressLines?.strictValue?.length > 0;
    }

    get isReturn() {
        return this.hasExpressLines && this.model.isReturn.strictValue;
    }

    get calculationAmountText(): string {
        return this.isRedeem ? 'Сумма выкупа' : 'Сумма выигрыша';
    }
}
