import {Injectable} from '@angular/core';
import {IDropdownStrategy} from './i-dropdown-strategy';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import { isNullOrUndefined } from '@koddington/ga-common';
import {Observable, of} from 'rxjs';
import {EnumExtensions} from '@koddington/ga-common';
import {WlMechanicEnabledReasons, WlMechanicEnabledReasonsExtensions} from 'src/app/services/autogen/Shared';

@Injectable({
  providedIn: 'root'
})

export class MechanicEnabledReasonsDropdownStrategy implements IDropdownStrategy<WlMechanicEnabledReasons> {

  convert(model: WlMechanicEnabledReasons): DropdownItem<WlMechanicEnabledReasons> {
    if (isNullOrUndefined(model)) {
      return undefined;
    }
    const item = new DropdownItem<WlMechanicEnabledReasons>();
    item.id = model.toString();
    item.entity = model;
    item.title = WlMechanicEnabledReasonsExtensions.format(model);

    return item;
  }

  getEntities(): Observable<Array<WlMechanicEnabledReasons>> {
    const types = EnumExtensions.toArray(WlMechanicEnabledReasons);
    return of(types);
  }
}

