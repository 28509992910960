<div>
    <label class="mt-2">Новая категория кэшбэка для пользователя</label>
    <div class="row">
        <div class="col-2">
            {{"Id пользователя"}}
        </div>
        <div class="col-2">
            {{"Дата выбора"}}
        </div>
    </div>
    <div class="mt-2 row">
        <app-strict-input-number class="col-2"
                                 placeholder="Id пользователя"
                                 [control]="model.userId">
        </app-strict-input-number>
        <strict-date-time-picker class="col-2 timeline-marker"
                                 placeholder="Дата выбора"
                                 [control]="model.selectDate">
        </strict-date-time-picker>
    </div>
    <div class="col-2">
        {{"Категории"}}
    </div>
    <div class="mt-2 row">
        <app-strict-autocomplete class="col-2"
                                 placeholder="Категория кэшбэка 1"
                                 [strategy]="cashbackCategoriesStrategy"
                                 [control]="model.firstCategory">
        </app-strict-autocomplete>
        <app-strict-autocomplete class="col-2"
                                 placeholder="Категория кэшбэка 2"
                                 [strategy]="cashbackCategoriesStrategy"
                                 [control]="model.secondCategory">
        </app-strict-autocomplete>
        <app-strict-autocomplete class="col-2"
                                 placeholder="Категория кэшбэка 3"
                                 [strategy]="cashbackCategoriesStrategy"
                                 [control]="model.thirdCategory">
        </app-strict-autocomplete>
        <app-strict-autocomplete class="col-2"
                                 placeholder="Категория кэшбэка 4"
                                 [strategy]="cashbackCategoriesStrategy"
                                 [control]="model.fourthCategory">
        </app-strict-autocomplete>
    </div>
    <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
</div>

<div *ngIf="loading">
    <app-loading></app-loading>
</div>
<hr>
<div class="mt-2 row">
    <app-strict-input-number class="col-3"
                             (keydown.enter)="search()"
                             [control]="searchModel.userId"
                             placeholder="Id пользователя"
                             [title]="'Id пользователя'">
    </app-strict-input-number>
</div>

<div *ngIf="!loading">
    <ga-table [data]="tableData" [hideArrows]="true"></ga-table>
    <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
