import { AbstractModelValidator } from '@koddington/ga-common';
import { BetCalculationLineViewModel } from '../../../models/events/components-models/bet-calculation-line-view-model';
import { StrictValidationRules } from '../../shared/validation-rules/strict-validation-rules';
import isDigit = StrictValidationRules.isDigit;

export class BetCalculationLineValidator  extends AbstractModelValidator<BetCalculationLineViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.id)
            .required('Поле обязательно');
    }
}
