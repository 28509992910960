import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserSegmentationDataComponent} from './components/user-segmentation-data/user-segmentation-data.component';
import {RouterModule} from '@angular/router';
import {SegmentsRoutes} from './segments.routes';
import {ReactiveFormsModule} from '@angular/forms';
import {UserSegmentationDataValidator} from './validators/user-segmentation-data-validator';
import {SharedModule} from '../shared/shared.module';
import { UserSyncProfilesComponent } from './components/user-sync-profiles/user-sync-profiles.component';
import { LastSegmentationStateComponent } from './components/last-segmentation-state/last-segmentation-state.component';
import {SegmentationLayoutComponent} from './components/new-segmenatation/segmentation-layout/segmentation-layout.component';
import {EventsModule} from '../events/events.module';
import {GaCommonModule} from '@koddington/ga-common';
import { SharedAreaCodegenAppModule } from '../../services/autogen/Shared';


@NgModule({
  declarations: [
    UserSegmentationDataComponent,
    UserSyncProfilesComponent,
    LastSegmentationStateComponent,
    SegmentationLayoutComponent,
  ],

  imports: [
    CommonModule,
    RouterModule.forChild(SegmentsRoutes),
    ReactiveFormsModule,
    SharedModule,
    EventsModule,
    GaCommonModule,
    SharedAreaCodegenAppModule
  ],
  exports: [

  ],
  providers: [
    UserSegmentationDataValidator
  ]
})
export class SegmentsModule {
}
