import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import { isNullOrUndefined } from '@koddington/ga-common';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {IAutocompleteItem, IAutocompleteStrategy, IDropdownStrategy} from '@koddington/ga-common';
import { SportMatchesListForm, SportMatchesService, WlSportMatchApiModel } from '../../../../../services/autogen/Events';

export class MatchEventsIdStrategy implements IAutocompleteStrategy<WlSportMatchApiModel>, IDropdownStrategy<WlSportMatchApiModel>  {
    private _termSource = new BehaviorSubject<string>('');
    private _source = new BehaviorSubject<WlSportMatchApiModel[]>([]);

    constructor(private _apiService: SportMatchesService) {
        this._termSource
            .pipe(
                switchMap((term) => {
                    return this._apiService.list(new SportMatchesListForm({matchId : term?.length > 0 ? Number(term) : null})).pipe(
                        filter(v => v?.results?.length > 0),
                        map(result => result.results)
                    );
                })
            ).subscribe((u) => this._source.next(u));
    }

    public convert(model: WlSportMatchApiModel): IAutocompleteItem<WlSportMatchApiModel> {
        return {
            entity: model,
            title: model.matchId.toString(),
            key: model.matchId.toString()
        };
    }

    public getSource(): Observable<Array<WlSportMatchApiModel>> {
        return this._source.asObservable();
    }

    public updateSource(term: string): void {
        this._termSource.next(term);
    }

    public getEntities(): Observable<Array<WlSportMatchApiModel>> {
        return this._apiService.list(new SportMatchesListForm({matchId: null})).pipe(
            filter(v => v?.results?.length > 0),
            map(result => result.results)
        );
    }
    public map(model: WlSportMatchApiModel): DropdownItem<WlSportMatchApiModel> {
        if (isNullOrUndefined(model)) {
            return {
                entity: model,
                title: '',
                id: null
            };
        }

        return {
            entity: model,
            title: model.matchId.toString(),
            id: model.matchId.toString()
        };
    }
    public getEntitiesForEmptyFields(count: number): Observable<Array<WlSportMatchApiModel>> {
        return this._apiService.list(new SportMatchesListForm({matchId: null, count: count})).pipe(
            filter(v => v?.results?.length > 0),
            map(result => result.results)
        );
    }
}
