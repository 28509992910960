import {IDropdownStrategy} from './i-dropdown-strategy';
import {WlCouponsTypesExtensions, WlCouponsTypes } from '../../../../../services/autogen/Shared';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {EnumExtensions} from '@koddington/ga-common';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CouponsDropdownStrategy implements IDropdownStrategy<WlCouponsTypes> {
  public getEntities(): Observable<Array<WlCouponsTypes>> {
    return of(EnumExtensions.toArray(WlCouponsTypes));
  }

  public convert(model: WlCouponsTypes): DropdownItem<WlCouponsTypes> {
    return {
      id: Number(model).toString(),
      title: WlCouponsTypesExtensions.format(model),
      entity: model
    };
  }
}
