<h1 style="padding: inherit">События</h1>
<div *ngIf="loading" class="loading">
    <app-loading></app-loading>
</div>
<div class="row ga-align-flex-end">
    <app-strict-input-number class="col-2"
                             title="ID пользователя"
                             [control]="model.userId"
    ></app-strict-input-number>
    <app-strict-input-number class="col-2"
                             title="Записей на страницу"
                             [control]="model.count"
                             [placeholder]="'Диапазон 10-100'"
    ></app-strict-input-number>
    <app-strict-dropdown class="col-4"
                         [control]="model.eventType"
                         [strategy]="userEventStrategy"
                         [options]="{title: 'Тип события', placeholder: 'Не выбран'}"
    ></app-strict-dropdown>
    <app-strict-input-boolean class="col-2"
                              label="Только последнее"
                              [control]="model.onlyLastRecord"
    ></app-strict-input-boolean>
    <div class="col-2" style="margin-bottom: 8px">
        <button class="btn btn-primary" (click)="search()">Поиск</button>
    </div>
</div>
<div class="container-fluid" *ngIf="!loading">
    <table class="table">
        <tr class="row table-info">
            <td class="col-1">
                Id
            </td>
            <td class="col-1">
                Id пользователя
            </td>
            <td class="col-2">
                Дата события
            </td>
            <td class="col-3">
                Тип события
            </td>
            <td class="col-5">
                Данные
            </td>
        </tr>

        <tr *ngFor="let event of result.userEvents.results" class="row"
            [class.text-info]="eventIsProcessed(event)">
            <td class="col-1">
                {{ event.id }}
            </td>
            <td class="col-1">
                {{ event.userId }}
            </td>
            <td class="col-2">
                {{ event.eventDate | moscowDateTime }}
            </td>
            <td class="col-3">
                {{ event.eventType | wlUserEventTypeTransform }}
            </td>
            <td class="col-5">
                <pre class="wl-payloads">{{ deserialize(event.payload) | json }}</pre>
            </td>
        </tr>
    </table>
    <ga-paging [pagedResult]="result.userEvents" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
