import { Component, OnInit } from '@angular/core';
import { AddX50BetViewModel } from '../../../models/tours/add-x50-bet-view-model';
import { AddXfiftyBetForm, EventsService } from 'src/app/services/autogen/Events';
import { AddX50BetValidator } from '../validators/add-x50-bet-validator';
import { filter, take } from 'rxjs/operators';
import { GaMessagingService } from '@koddington/ga-common';
import { GaStrictModelFactory } from '@koddington/ga-common';

@Component({
    selector: 'app-add-x50-bet',
    templateUrl: './add-x50-bet.component.html',
    styleUrls: ['./add-x50-bet.component.css']
})
export class AddX50BetComponent implements OnInit {
    viewModel = new AddX50BetViewModel();
    validator = new AddX50BetValidator();

    constructor(private readonly toursService: EventsService,
                private readonly messaging: GaMessagingService) {
    }

    ngOnInit(): void {
    }

    save(): void {
        if (!this.validator.isValid(this.viewModel)) {
            return;
        }

        const form = GaStrictModelFactory.fromStrictToModel(AddXfiftyBetForm, this.viewModel);
        this.toursService.addXfiftyBet(form)
            .pipe(
                take(1),
                filter(value => !this.messaging.tryShowError(value))
            ).subscribe(() => this.messaging.showMessage('Ставка успешно добавлена'));
    }
}
