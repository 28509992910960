import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { isNullOrUndefined, StrictFormControlBuilder } from '@koddington/ga-common';
import { SearchNavigationService } from '../../shared/services/search-navigation.service';
import { StrictFormControl } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-broadcasts-layout',
    templateUrl: './broadcasts-layout.component.html',
    styleUrls: ['./broadcasts-layout.component.css']
})
export class BroadcastsLayoutComponent implements OnInit {

    private tabsControl: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();

    constructor(private readonly activeRoute: ActivatedRoute,
                private readonly navigation: SearchNavigationService) {
    }

    ngOnInit(): void {
        this.activeRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
            if (!isNullOrUndefined(params.tab)) {
                this.tab.strictValue = params.tab;
            }
        });
    }

    public onTabChange(tabName: string): void {
        const params: Params = {
            tab: tabName
        };

        this.navigation.search(this.activeRoute, params);
    }

    get tab(): StrictFormControl<string> {
        return this.tabsControl;
    }

    get isBroadcastsAllow(): boolean {
        return this.tab.strictValue === 'Broadcasts';
    }

    get isPushAllow(): boolean {
        return this.tab.strictValue === 'Push';
    }

    get isBetsResultsSubscriptionsAllow(): boolean {
        return this.tab.strictValue === 'BetsResultsSubscriptions';
    }

    get isPushBroadcastingStatisticsAllow(): boolean {
        return this.tab.strictValue === 'PushBroadcastingStatistics';
    }

    get isSmsBroadcastingStatisticsAllow(): boolean {
        return this.tab.strictValue === 'SmsBroadcastingStatistics';
    }

}
