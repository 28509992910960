import { Component, OnInit } from '@angular/core';
import { AddUserCashbackViewModel } from '../../../../models/events/components-models/add-user-cashback-view-model';
import { EventsService, MockAddUserCashbackForm, WlUserCashback } from '../../../../services/autogen/Events';
import { formatDateTime, GaMessagingService, GaPagedResult, GaPagingForm, GaTableData, isNullOrUndefined } from '@koddington/ga-common';
import { AddUserCashbackValidator } from '../../validators/add-user-cashback-validator';
import { map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CashbackCategoriesStrategy } from './strategies/cashback-categories-strategy';
import { SearchNavigationService } from '../../../shared/services/search-navigation.service';
import { ActivatedRoute, Params } from '@angular/router';
import { UsersListByIdForm } from '../../../../services/autogen/Shared';
import { GaStrictModelFactory } from '@koddington/ga-common';
import { UserCashbackSearchModel } from './models/user-cashback-search-model';
import { GuideCashbackListViewModel } from '../add-guide-cashback/models/guide-cashback-list-view-model';

@UntilDestroy()
@Component({
  selector: 'app-add-user-cashback',
  templateUrl: './add-user-cashback.component.html',
  styleUrls: ['./add-user-cashback.component.css']
})
export class AddUserCashbackComponent implements OnInit {

    public readonly model: AddUserCashbackViewModel = new AddUserCashbackViewModel();
    public readonly cashbackCategoriesStrategy: CashbackCategoriesStrategy;
    public readonly searchModel: UserCashbackSearchModel = new UserCashbackSearchModel();
    public pagedResult: GaPagedResult<WlUserCashback>;
    public tableData: GaTableData<WlUserCashback>;
    public loading = true;
    constructor(private readonly service: EventsService,
                private readonly validator: AddUserCashbackValidator,
                private readonly _navigation: SearchNavigationService,
                private readonly _activeRoute: ActivatedRoute,
                private readonly messaging: GaMessagingService) {
        this.cashbackCategoriesStrategy = new CashbackCategoriesStrategy(service);
    }

    public ngOnInit(): void {
        this.updateCurrentTableState();
    }

    public add(): void {
        this.loading = true;
        const errors = this.validator.validate(this.model);
        if (errors.length > 0) {
            return;
        }
        const form = new MockAddUserCashbackForm();
        form.userId = this.model.userId.strictValue;
        form.selectDate = this.model.selectDate.strictValue;
        form.categories = '|' + [
            this.model.firstCategory.strictValue.categoryId,
            this.model.secondCategory.strictValue.categoryId,
            this.model.thirdCategory.strictValue.categoryId,
            this.model.fourthCategory.strictValue.categoryId,
        ].filter(Boolean).join('|') + '|';


        this.service.addUserCashback(form).pipe(take(1), untilDestroyed(this))
            .subscribe(value => {
                this.messaging.showMessage('Категории кэшбэка для пользователя выбраны');
                this.updateCurrentTableState();
            });
    }
    private updateCurrentTableState() {
        this._activeRoute.queryParams.pipe(
            map(value => this.initFindViewModel(value)),
            map(() => this.createForm()),
            take(1)
        ).subscribe((value) => this.load(value));
    }

    private initFindViewModel(params: Params) {
        this.searchModel.userId.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
        this.searchModel.offset.strictValue = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
        this.searchModel.count.strictValue = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
    }
    private createForm(): UsersListByIdForm {
        return GaStrictModelFactory.fromStrictToModel(UsersListByIdForm, this.searchModel);
    }

    private load(form: UsersListByIdForm): void {
        this.service.getUserSelectedCashbackCategoriesList(form)
            .pipe(untilDestroyed(this))
            .subscribe(res => {
                this.pagedResult = res;
                this.mapTableData(res.results);

                this.loading = false;
            });
    }
    private handleFilterChange(): void {
        const params: Params = {
            tab: 'Cashback',
            userId: this.searchModel.userId.hasStrictValue ? Number(this.searchModel.userId.strictValue) : null,
            offset: this.searchModel.offset.hasStrictValue ? this.searchModel.offset.strictValue : 0,
            count: this.searchModel.count.hasStrictValue ? this.searchModel.count.strictValue : 30
        };

        this._navigation.search(this._activeRoute, params);
    }
    public search(): void {
        this.searchModel.offset.strictValue = 0;
        this.handleFilterChange();
    }
    public pageChanged(form: GaPagingForm): void {
        this.searchModel.offset.strictValue = form.offset;
        this.searchModel.count.strictValue = form.count;

        this.handleFilterChange();
    }
    private mapTableData(results: WlUserCashback[]) {
        this.tableData = new GaTableData<WlUserCashback>()
            .addSimpleColumn(u => u.userId, { title: 'Id пользователя', widthSize: 170 })
            .addSimpleColumn(u => formatDateTime(u.selectDate), { title: 'Дата выбора', widthSize: 170 })
            .addSimpleColumn(u => u.categories, { title: 'Id категорий', widthSize: 170 })
            .setData(results);
    }
}
